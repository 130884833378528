.input input[type='text'] {
    border: 1px solid #cccccc;
    padding: 6px;
    border-radius: 5px;
}

.input input[type='number'] {
    border: 1px solid #cccccc;
    padding: 6px;
    border-radius: 5px;
}

.input input[type='number']:focus-visible {
    border: 1px solid #cccccc;
}

.input input[type='text']:focus-visible {
    border: 1px solid #cccccc;
}

.datepicker .react-datepicker__input-container input[type='text'] {
    border: 1px solid #cccccc;
    padding: 6px;
    border-radius: 5px;
}
.datepicker .react-datepicker__input-container input[type='text']:focus-visible {
    border: 1px solid #cccccc;
}

.mandatory {
    color: #d52b1e !important;
}

.access-denied-page{
    margin:100px;
    font-size:2.5rem;
}

.display-4 {
    font-size: calc(0.475rem + 2.7vw) !important;
    font-weight: 300;
    line-height: 1.2;
}