.dashboard .table {
    margin-top: 16px;
}
.dashboard .table .table-head{
    background-color: #d52b1e;
    color: white;
}

.dashboard .table .table-head th{
    word-wrap: break-word;
    max-width: 150px;
}

.dashboard .table tbody td{
    word-wrap: break-word;
    max-width: 150px;
}
.table-head-th{
    background-color: #d52b1e !important;
    color: white !important;
}