.intakeform{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 60px;
}
.intakeform .intakeform-element .currency-element{
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
}
.intakeForm .form-submit{
    text-align: center;
    margin: 30px;
}
.mandatory {
    color: #d52b1e !important;
}
.button-element {
  margin-left: 10px;
}
.spinner-styles {
  margin-left: 500px;
  margin-top: 100px
}
