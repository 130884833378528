.navbar-style {
    padding: 0px 10px;
}

.navbar-style .navbar-brand img {
    margin: 0px 15px;
}

.navbar-style .profile-section {
    margin: 0px 15px;
}

.navbar-style .profile-section .profile {
    background-color: #ededed;
    min-height: 50px;
    min-width: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.navbar-style .profile-section .profile .user-name {
    color: #3d8bfd;
    text-transform: uppercase;
    text-align: center;
}

.navbar-style .profile-section .profile .dropdown-menu.show {
    display: block;
    right: 0;
    margin-top: 45%;
}

.breadcrumb-item a{
    text-decoration: none;
    color: black !important;
}