.generic-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    display: block !important;;
  }
  .generic-modal .generic-modal-content {
    width: 40%;
    height: 31%;
    background-color: #ffffff;
    transform: translate(5%,2%);
    margin-left: 27%;
    margin-top: 16%;
  }
  .generic-modal .generic-modal-content .generic-modal-header, .generic-modal .generic-modal-content .generic-modal-footer {
    padding: 0;
  }
  .generic-modal .generic-modal-content .generic-modal-title {
    margin: 0px;
  }
  .generic-modal .generic-modal-content .generic-modal-body {
    padding: 0px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    max-height: calc(100% - 120px);
    overflow-y: scroll;
  }
  .button-styles {
    margin-left: 210px;
    margin-top: 10px;
  }
  .no-button-styles {
    margin-top:20px;
    font-size:18px;
    text-align:center;
    margin-left:0px
  }

  .button-div-space{
    margin-top:30px;
    text-align:center
  }
  .no-go-button-styles {
    margin-left: 70px;
    width: 70%;
    height: 90%;
  }
  .submit-button-style {
    margin: 20px
  }
  .nogo-modal-content {
    background-color: #ffffff;
    transform: translate(5%,2%);
    margin-left: 31%;
    margin-top: 15%;
    Padding:10px;
    height: 50%;
    width: 45%;
  }
  .comment-textarea{
    height:150px;
    margin-top:10px;
    width:500px
  }

  .button-box{
    margin-top:105px;
    text-align:center;
  }

  .comment-space{
    height:100px;
    margin-top:30px;
    text-align:center
  }
  .comment-space-child{
    margin-top:20px;
    font-size:19px
  }
  .nogo-modal-title {
    margin: 10px
  }
  .nogo-title {
    margin-left: 70px;
    padding-top: 15px; 
  }
  .nogo-cancel {
    margin-left: 10px;
  }
  .no-button-cancel-popup {
    margin-left: 10px;
    margin-top: 10px;
  }
  .nogo-submit {
    margin-left: 140px;
  }
  .lilly-logo-style {
    margin-left: 220px;
  }
  .cancel-close {
    margin-left: 470px;
    margin-top: 10px;
  }
  
  
