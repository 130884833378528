.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  display: block !important;
  ;
}

.modal .modal-content {
  width: 90%;
  height: 90%;
  background-color: #ffffff;
  transform: translate(5%, 2%);
}

.modal .modal-content .modal-header,
.modal .modal-content .modal-footer {
  padding: 10px;
}

.modal .modal-content .modal-title {
  margin: 0px;
}

.modal .modal-content .modal-body {
  padding: 0px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  max-height: calc(100% - 120px);
  overflow-y: scroll;
}

.vvpm-container {
  display: grid;
  grid-template-columns: 0.3fr 0.8fr;
  grid-gap: 1rem;
  margin: 10px;
}

.vvpm-container .vvpm-selected .vvpmchip {
  display: grid;
  grid-template-columns: 0.9fr 0.1fr;
  border: 1px solid #ededed;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 3px;
}

.vvpm-container .vvpm-selected .vvpmchip span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vvpm-container .vvpmdata .vvpmkeyword {
  grid-template-columns: 0.2fr 1fr;
}

.vvpm-container .vvpmdata .vvpmtable .sticky-top {
  position: sticky;
  top: -1px;
  z-index: 30;
  color: #eeeeee;
  background-color: #d52b1e;
}

#default-checkbox {
  display: none
}

.form-check-1 {
  margin-right: 10px;
  float: left;
  border: 1px solid #f4f0eb;
  border-style: solid;
  border-radius: 13px;
  padding: 0px;
  float: left;
}

.form-check {
  padding-left: 4px !important;
  padding: 4px;
}

.search_parameter {
  margin-top: 10px;
  font-size: 15px;
}

.vvpm-selected-v1 {
  margin-top: 15px;
}

.header-message {
  padding: 5px;
  background: aliceblue;
  padding-left: 13px;
  margin: 5px;
}

.button-styles {
  margin-left: 100px;
  margin-top: 50px;
}

.no-button-styles {
  margin-top: 50px;
  margin-left: 10px;
}

.no-go-button-styles {
  margin: 20px;
  width: 70%;
  height: 90%;
}

.submit-button-style {
  margin: 20px
}

.rejection-comments {
  background: aliceblue;
  text-align: center;
  padding: 5px;
  margin-top: 50px;
}

.no_go_comment {
  margin-top: 10px;
}

.search_keyword_width {
  width: 75% !important;
}

.table-head-th{
  background-color: #d52b1e !important;
  color: white !important;
}